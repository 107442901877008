.col{
   
}
.col > body{
    background-color: rgb(40, 44, 56) !important;
}
.col>body>div{
    background-color: rgb(40, 44, 56) !important;
}
.col>body>div>div{
    background-color: #1e1f25 !important;
    border-radius: 10px;
}
.col>body>div>div :nth-child(2) > div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: rgb(40, 44, 56) !important;
    border-radius: 5px;
    
   & p{
    margin-bottom: 4px !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between ;
    align-items: center ;   
    @media only screen and (max-width:1200px){
        flex-direction: column !important;
        justify-content: space-between ;
        align-items: flex-start ;   
    }
    & > span{
        display: flex !important;
        @media only screen and (max-width:1200px){
        width: 100% !important;
    }
          
    }
   }



} 
 .col>body>div>div :nth-child(3){
    & p{
      display: flex !important;
      padding-bottom: 5px !important;
      align-items: start !important;

    }
}