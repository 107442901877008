/* CK editor */
:root {
  --ck-border-radius: 10px !important;
  --ck-color-toolbar-background: #282c38 !important;
  --ck-color-toolbar-border: #3c4254 !important;
  /* --ck-color-button-default-active-background */
  --ck-color-button-default-background: #3c4254 !important;
  --ck-color-button-default-hover-background: hsl(270, 1%, 22%);
  --ck-color-button-default-active-background: hsl(270, 2%, 20%);
  --ck-color-button-default-active-shadow: hsl(270, 2%, 23%);
  --ck-color-button-default-disabled-background: var(--ck-custom-background);

  --ck-color-list-background: var(--ck-custom-background);
  --ck-color-list-button-hover-background: var(--ck-color-base-foreground);
  --ck-color-list-button-on-background: #1e1f25 !important;
  --ck-color-list-button-on-background-focus: #282c38 !important;
  --ck-color-list-button-on-text: var(--ck-color-base-background);

  --ck-color-base-foreground: var(--ck-custom-background);
  --ck-color-focus-border: #fff !important;
  --ck-color-text: #fff !important;
  --ck-color-shadow-drop: hsla(0, 0%, 0%, 0.2);
  --ck-color-shadow-inner: hsla(0, 0%, 0%, 0.1);

  --ck-color-button-on-background: var(--ck-custom-foreground);
  --ck-color-button-on-hover-background: #3c4254 !important;
  --ck-color-button-on-active-background: hsl(255, 4%, 14%);
  --ck-color-button-on-active-shadow: hsl(240, 3%, 19%);
  --ck-color-button-on-disabled-background: var(--ck-custom-foreground);

  --ck-color-button-action-background: hsl(168, 76%, 42%);
  --ck-color-button-action-hover-background: hsl(168, 76%, 38%);
  --ck-color-button-action-active-background: hsl(168, 76%, 36%);
  --ck-color-button-action-active-shadow: hsl(168, 75%, 34%);
  --ck-color-button-action-disabled-background: hsl(168, 76%, 42%);
  --ck-color-button-action-text: var(--ck-custom-white);

  --ck-color-button-save: hsl(120, 100%, 46%);
  --ck-color-button-cancel: hsl(15, 100%, 56%);
  --ck-color-tooltip-text: red !important;

  --ck-color-button-default-hover-background: #282c38 !important;
}

/* CK Editor */

.ck-editor__editable_inline {
  max-width: 100%;
  height: 430px !important;
  padding: 10px !important;
  background: #282c38 !important;
  /* Base Color/Grey 60 */

  font-size: 102x;
  border: 1px solid #3c4254 !important;
  border-radius: 0px 0px 10px 10px !important;
  font-family: "Inter";

  /* Base Color/Grey 0 - White */

  color: #ffffff !important;
}

.ck-editor__editable_inline::-webkit-scrollbar {
  width: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
/* body {
  overflow: hidden;
} */

body {
  max-width: 100vw;
}

body::-webkit-scrollbar {
  width: 0px;
}

p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

/* SideBar */

.MenuItemContent {
  padding: 0px 16px;
  gap: 16px;

  display: flex;
  align-items: center;

  width: 100%;
  height: 44px;
  text-decoration: none;
  color: #757b8c;
}

.MenuItemContent:hover {
  color: #757b8c;
}

.active {
  background: #282c38;
  color: #ffffff !important;
}

/* Dropdown Menu */

.Dropdown-Menu-Ul {
  background: rgba(20, 21, 24, 0.3);
  /* Base Color/Grey 100 */

  border: 1px solid #1e1f25;
  box-shadow: 0px 0px 50px rgba(36, 108, 249, 0.1);
  backdrop-filter: blur(10px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 10px 0px 10px 10px;
}

.Dropdown-Menu-Li {
  padding: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;

  cursor: pointer;
}

.Dropdown-Menu-Li:hover {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;

  background-color: transparent;
}

.Dropdown-Icon {
  width: 24px;
  height: 24px;
}

/* Navlink Profilim */

.NavlinkProfilim {
  text-decoration: none;
  background: none;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;
}

.NavlinkProfilim:hover {
  color: #ffffff;
}

/* Modal Parts */

.modal-content {
  background-color: transparent;
  border: none;
}

/* Mui OpenModal */
.css-sghohy-MuiButtonBase-root-MuiButton-root {
  background: transparent !important;
  border-radius: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
  transition: none !important;
}

.MuiButtonBase-root {
  padding: 0 !important;
  margin: 0 !important;
}

/* React Bootstrap Checkbox */

.form-check-input[type="checkbox"] {
  border-radius: 4px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: #282c38;
  border: 1px solid #3c4254;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact;
}

.form-check-input:focus {
  /* border-color: #86b7fe; */
  outline: 0;
  /* box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%); */
  box-shadow: none !important;
}

.form-check-input:checked {
  background-color: #246cf9;
  border-color: #246cf9;
}

/* Paginations css */

.MuiButtonBase-root-MuiPaginationItem-root {
  /* color: #ffffff !important; */
  font-size: 11px !important;
}

.MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  color: #246cf9 !important;
  background-color: transparent !important;
  font-size: 15px !important;
  font-weight: 700;
}

/* üç nokta */
.MuiPaginationItem-root {
  color: #ffffff !important;
}

/* Bootstrap Dropdown for işlemler */

.dropdown-toggle::after {
  display: none;
}

/* Mui Date Picker */
.MuiInputBase-root-MuiOutlinedInput-root {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #ffffff !important;
  border: none !important;
  border-radius: 10px !important;

  background: #282c38 !important;
  /* Base Color/Grey 60 */
}

.MuiInputBase-root
  .MuiOutlinedInput-root
  .MuiInputBase-colorPrimary
  .MuiInputBase-formControl
  .MuiInputBase-adornedEnd
  .MuiInputBase-root-MuiOutlinedInput-root
  input:focus {
  outline: none !important;
  border: 1px solid red !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input:focus {
  outline: none !important;
  border: none !important;
}

.MuiFormControl-root .MuiTextField-root .css-1u3bzj6-MuiFormControl-root-MuiTextField-root :hover {
  border: none !important;
  outline: none !important;
}

.css-1lwbda4-MuiStack-root {
  border: 1px solid #3c4254 !important;
  border-radius: 10px !important;
}

.css-1lwbda4-MuiStack-root :hover {
  border: none;
}

.css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
}

.MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: none !important;
  border-width: 0px !important;
  outline: none !important;
}

/* Hide Input Number Format */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Navbar PopOver */

.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  padding: 0;

  width: 300px;
  height: 300px;

  background: rgba(20, 21, 24, 0.5) !important;
  border: 1px solid #1e1f25 !important;
  box-shadow: 0px 0px 50px rgba(36, 108, 249, 0.1) !important;
  backdrop-filter: blur(10px) !important;
  border-radius: 10px 0px 10px 10px !important;
}
